import React, { useState, useEffect, useRef } from 'react';
import supabase from '../../supabaseClient';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import ServicesModal from './ServicesModal';
import ServicesList from './ServicesModal';
import '../../fonts/Roboto-Regular-normal.js'
import '../../fonts/Roboto-Bold-bold.js'

const OfferConfigurator = () => {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [quantityInput, setQuantityInput] = useState({});
  const [promotionInput, setPromotionInput] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [nipNumber, setNipNumber] = useState('');
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [dateOffer, setDateOffer] = useState('');
  const [validUntil, setValidUntil] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const [introText, setIntroText] = useState("Poniżej znajduje się nasza spersonalizowana oferta z okazji 10. rocznicy 'French Touch, La Belle vie.'");
  const [packs, setPacks] = useState([]);
  const [offerNumber, setOfferNumber] = useState(''); // Add state for offer number

  const packSelectionMapRef = useRef(new Map());

  const fetchPacks = async () => {
    const { data, error } = await supabase.from('packs').select('*');
    if (error) {
        console.error('Error fetching packs:', error);
        return;
    }
    if (data.length === 0) {
        console.log('No data found in packs table.');
        return;
    }
    setPacks(data);
    data.forEach(pack => {
        packSelectionMapRef.current.set(pack.id, {
            totalServices: 0,
            selectedServices: 0,
            packPrice: pack.pack_price,
            isFullPackSelected: false,
        });
    });
};

  useEffect(() => {
    fetchServices();
    fetchPacks();
  }, []);

  const fetchServices = async () => {
    const { data, error } = await supabase.from('services').select('*');
    if (error) {
        console.error('Error fetching services:', error);
        return;
    }
    setServices(data);
    data.forEach(service => {
        if (service.pack_id && packSelectionMapRef.current.has(service.pack_id)) {
            const packInfo = packSelectionMapRef.current.get(service.pack_id);
            packInfo.totalServices++;
        }
    });
};

const handleServiceSelection = (selectedServicesArray) => {
    packSelectionMapRef.current.forEach((packInfo, key) => {
        packInfo.selectedServices = 0;
    });

    selectedServicesArray.forEach(service => {
        if (service.pack_id && packSelectionMapRef.current.has(service.pack_id)) {
            packSelectionMapRef.current.get(service.pack_id).selectedServices++;
        }
    });

    packSelectionMapRef.current.forEach((packInfo, packId) => {
        const totalServices = services.filter(service => service.pack_id === packId).length;
        packInfo.totalServices = totalServices;
        packInfo.isFullPackSelected = packInfo.selectedServices === packInfo.totalServices;
    });

    setSelectedServices(selectedServicesArray);
};

  const handleAddService = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleQuantityChange = (serviceId, quantity) => {
    quantity = parseInt(quantity, 10);
    if (isNaN(quantity)) quantity = 1;

    const updatedServices = selectedServices.map(service =>
      service.id === serviceId ? { ...service, quantity } : service
    );
    setSelectedServices(updatedServices);
};

const handlePromotionChange = (serviceId, promotion) => {
    promotion = parseFloat(promotion);
    if (isNaN(promotion) || promotion < 0 || promotion > 100) promotion = 0;

    const updatedServices = selectedServices.map(service =>
      service.id === serviceId ? { ...service, promotion } : service
    );
    setSelectedServices(updatedServices);
};

const updatePackSelection = () => {
    packs.forEach(pack => {
        packSelectionMapRef.current.set(pack.id, {
            totalServices: 0,
            selectedServices: 0,
            packPrice: pack.pack_price,
            isFullPackSelected: false,
        });
    });

    services.forEach(service => {
        if (service.pack_id && packSelectionMapRef.current.has(service.pack_id)) {
            const packInfo = packSelectionMapRef.current.get(service.pack_id);
            packInfo.totalServices++;
        }
    });

    selectedServices.forEach(service => {
        if (service.pack_id && packSelectionMapRef.current.has(service.pack_id)) {
            const packInfo = packSelectionMapRef.current.get(service.pack_id);
            packInfo.selectedServices++;
        }
    });

    packSelectionMapRef.current.forEach((packInfo, packId) => {
        packInfo.isFullPackSelected = packInfo.totalServices > 0 && packInfo.selectedServices === packInfo.totalServices;
    });
};

  const calculateTotalPrice = () => {
    const subtotal = calculateTotalBeforeDiscounts();
    const discounts = calculateTotalDiscounts();
    return subtotal - discounts;
};

  const calculateTotalBeforeDiscounts = () => {
    let subtotal = 0;
    const packPriceApplied = new Set();

    packSelectionMapRef.current.forEach((packInfo, packId) => {
        if (packInfo.isFullPackSelected) {
            subtotal += packInfo.packPrice;
            packPriceApplied.add(packId);
        }
    });

    selectedServices.forEach(service => {
        if (!service.pack_id || !packPriceApplied.has(service.pack_id)) {
            subtotal += (service.prix_ft || 0) * (service.quantity || 1);
        }
    });

    return subtotal;
};

const calculateTotalDiscounts = () => {
    let discounts = 0;

    selectedServices.forEach(service => {
        if (!service.pack_id || !packSelectionMapRef.current.get(service.pack_id).isFullPackSelected) {
            const fullPrice = (service.prix_ft || 0) * (service.quantity || 1);
            const discountedPrice = fullPrice * ((100 - (service.promotion || 0)) / 100);
            discounts += (fullPrice - discountedPrice);
        }
    });

    return discounts;
};

const groupedServices = selectedServices.reduce((acc, service) => {
  if (!acc[service.categorie]) {
    acc[service.categorie] = [];
  }
  acc[service.categorie].push(service);
  return acc;
}, {});
  
  const groupedByCategory = selectedServices.reduce((acc, service) => {
  if (!acc[service.categorie]) {
    acc[service.categorie] = {};
  }
  if (!acc[service.categorie][service.sous_categorie]) {
    acc[service.categorie][service.sous_categorie] = [];
  }
  acc[service.categorie][service.sous_categorie].push(service);
  return acc;
}, {});

const generatePDF = () => {
  setIsGeneratingPDF(true);
  const doc = new jsPDF();
  doc.setFont('Roboto');

  const logoUrl = '/images/FT-2024.png';
  doc.addImage(logoUrl, 'PNG', 13, 8, 30, 25);

  const addressLines = [
    'Anor Sp. z o.o.',
    'ul. Grzybowska 3/12',
    '00-132 Warszawa',
    'NIP: 527-302-90-63',
    'KRS: 0001003102',
    'tel.: +48 537 500 564',
    'e - mail: office@frenchtouchb2b.com'
  ];
  doc.setFontSize(9);
  doc.text(addressLines, 15, 40, { maxWidth: 180 });

  // Add offer date and validity to the top right
  if (dateOffer) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`Data oferty: ${dateOffer}`, 200, 15, { align: 'right' });
  }

  if (validUntil) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`Oferta ważna do: ${validUntil}`, 200, 20, { align: 'right' });
  }

  let startY = 70; // Start a bit lower for client details

  // Add client details below the company info
  doc.setFontSize(9);
  doc.setFont('Roboto', 'bold');
  doc.text('Oferta dla:', 15, startY);
  if (companyName) {
    startY += 4; // Move to the next line for client's name
    doc.text(companyName, 15, startY);
    startY += 4; // Additional space after the client's name
  }

  if (address) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`Adres: ${address}`, 15, startY);
    startY += 4;
  }

  if (postalCode) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`Kod pocztowy: ${postalCode}`, 15, startY);
    startY += 4;
  }

  if (city) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`Miasto: ${city}`, 15, startY);
    startY += 4;
  }

  if (nipNumber) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`NIP: ${nipNumber}`, 15, startY);
    startY += 1; // Adjust space here to reduce the gap
  }

  doc.setFontSize(14);
  doc.setFont('Roboto', 'bold');
  doc.text('Oferta Handlowa', 105, 15, { align: 'center' }); // Centered title
  
  if (offerNumber) { // Add offer number if it exists
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(offerNumber, 105, 20, { align: 'center' });
  }

  

  const introTextParagraphs = [
    'Szanowni Państwo,',
    introText // Use the introText state here
  ];
  doc.setFontSize(9);
  doc.setFont('Roboto', 'normal');
  introTextParagraphs.forEach(paragraph => {
    startY = addNewPageIfNeeded(startY + 8, doc);
    doc.text(paragraph, 15, startY, { maxWidth: 180 });
  });

  doc.setFont('Roboto', 'normal');

  startY = addNewPageIfNeeded(startY + 5, doc);

  let firstTableHeaderShown = false;

  Object.entries(groupedServices).forEach(([category, services], index) => {
    startY = addNewPageIfNeeded(startY + 7, doc);

    doc.setFontSize(9);
    doc.setFont('Roboto', 'bold');
    doc.text(category, 15, startY);
    startY += 3;

    doc.setDrawColor(100, 100, 100);
    doc.line(15, startY, 195, startY);
    doc.setDrawColor(0);
    startY += 5;

    const sousCategorieGroup = services.reduce((acc, service) => {
      acc[service.sous_categorie] = acc[service.sous_categorie] || [];
      acc[service.sous_categorie].push(service);
      return acc;
    }, {});

    Object.entries(sousCategorieGroup).forEach(([sousCategorie, items]) => {
      startY = addNewPageIfNeeded(startY + 1, doc);
      doc.setFontSize(9);
      doc.setFont('Roboto', 'bold');
      doc.text(sousCategorie, 15, startY);
      startY += 3;

      const formatNumber = (value) => {
        return new Intl.NumberFormat('en-US').format(value);
      }

      const tableRows = items.map(item => {
        const isFullPackSelected = item.pack_id && packSelectionMapRef.current.get(item.pack_id)?.isFullPackSelected;
        const price = parseFloat(item.prix_ft) || 0;
        const quantity = parseInt(item.quantity, 10) || 1;
        const discount = parseFloat(item.promotion) || 0;
        const finalPrice = price * quantity * (1 - discount / 100);
        
        return {
          module: item.module,
          quantity: isFullPackSelected ? '' : (price > 0 ? quantity.toString() : ''),
          prix_ft: isFullPackSelected ? '' : (price > 0 ? formatNumber(price.toFixed(2)) : ''),
          promotion: isFullPackSelected ? '' : (price > 0 ? discount.toString() + '%' : ''),
          finalPrice: isFullPackSelected ? '' : (price > 0 ? formatNumber(finalPrice.toFixed(2)) : '')
        };
      });

      doc.autoTable({
        columns: [
          { title: "Moduł", dataKey: "module" },
          { title: "Ilość", dataKey: "quantity" },
          { title: "Cena", dataKey: "prix_ft" },
          { title: "Rabat (%)", dataKey: "promotion" },
          { title: "Cena total", dataKey: "finalPrice" }
        ],
        body: tableRows,
        startY: startY,
        styles: { 
          font: 'Roboto',
          fontSize: 9, // Set a consistent font size for table text
          fillColor: [255, 255, 255],
          lineWidth: 0
        },
        columnStyles: {
          module: { 
            cellWidth: 80,
            font: 'Roboto',
            fontSize: 9
          },
          quantity: { 
            cellWidth: 20,
            font: 'Roboto',
            fontSize: 9
          },
          prix_ft: { 
            cellWidth: 30,
            font: 'Roboto',
            fontSize: 9
          },
          promotion: { 
            cellWidth: 20,
            font: 'Roboto',
            fontSize: 9
          },
          finalPrice: { 
            cellWidth: 30,
            font: 'Roboto',
            fontSize: 9
          }
        },
        headStyles: { 
          font: 'Roboto',
          fillColor: [255, 255, 255],
          textColor: [190, 190, 190],
          halign: 'left',
          fontSize: 7
        },
        bodyStyles: { 
          font: 'Roboto',
          valign: 'top',
          lineHeightFactor: 1.5 // Adjust line height factor
        },
        textColor: [50, 50, 50],
        alternateRowStyles: { 
          fillColor: [249, 249, 249] // Alternate row colors
        },
        theme: 'grid',
        willDrawCell: function(data) {
          if (data.row.section === 'body' && data.cell.y >= 280) {
            doc.addPage();
            data.cell.y = 20;
            startY = 20;
          }
        },
        showHead: firstTableHeaderShown ? 'never' : 'firstPage',
      });

      if (!firstTableHeaderShown) {
        firstTableHeaderShown = true;
      }

      startY = doc.lastAutoTable.finalY + 5;
    });
  });

  doc.setFontSize(9);
  const pageWidth = doc.internal.pageSize.width;
  const rightMargin = 25;
  const leftMargin = 15;
  const xPositionForValues = pageWidth - rightMargin;

  startY += 5;

  doc.setFont('Roboto', 'bold');
  doc.text('Oryginalna cena', leftMargin, startY);
  doc.text('Rabat', leftMargin, startY + 5);

  doc.setFont('Roboto', 'bold');
  doc.text('Cena końcowa', leftMargin, startY + 10);

  const paddingRight = 10;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      .format(value)
      .replace(/\.00$/, '') + ' PLN';
  }

  doc.setFont('Roboto', 'bold');
  doc.text(formatCurrency(calculateTotalBeforeDiscounts()), xPositionForValues, startY, { align: 'right' });
  doc.text(`-${formatCurrency(calculateTotalDiscounts())}`, xPositionForValues, startY + 5, { align: 'right' });

  doc.setFont('Roboto', 'bold');
  doc.text(formatCurrency(calculateTotalPrice()), xPositionForValues, startY + 10, { align: 'right' });

  startY += 20;

  doc.setFont('Roboto', 'normal');
  doc.text("Wszystkie ceny podane w ofercie są cenami netto", 15, startY);
  startY += 5;

  if (paymentTerms) {
    doc.setFontSize(9);
    doc.setFont('Roboto', 'normal');
    doc.text(`Warunki Platnosci: ${paymentTerms}`, 15, startY);
    startY += 10;
  }

  startY = addNewPageIfNeeded(startY + 5, doc);
  doc.setFont('Roboto', 'normal');
  doc.text("W razie jakichkolwiek pytań prosimy o kontakt.", 15, startY);
  doc.text("Pozdrawiam,", 15, startY + 5);

  const signatureImageUrl = '/images/sign-test.png';
  startY = addNewPageIfNeeded(startY + 10, doc);
  doc.addImage(signatureImageUrl, 'PNG', 15, startY, 40, 20);

  startY += 20;
  doc.setFontSize(9);
  doc.setFont('Roboto', 'normal');
  doc.text('Eric SALVAT', 15, startY);
  startY += 5;
  doc.text('CEO of ANOR', 15, startY);
  doc.setFont('Roboto', 'normal');

  addFooter(doc);

  const formattedDate = new Date(dateOffer).toLocaleDateString('pl-PL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).replace(/\//g, '-');
  const pdfFileName = `Oferta-${companyName}-${formattedDate}.pdf`;

  doc.save(pdfFileName);
  setIsGeneratingPDF(false);
};

// Adjust the addNewPageIfNeeded function to ensure doc is passed correctly
const addNewPageIfNeeded = (currentY, doc) => {
  if (currentY >= 280) {
    doc.addPage();
    return 20;
  }
  return currentY;
};


// Add the addFooter function
const addFooter = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();

  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(7);
    doc.setTextColor(169, 169, 169);
    const footerText = "ANOR Sp. z o.o., ul. Grzybowska 3/12, 00-132 Warszawa, NIP: 527-302-90-63, KRS:0001003102, tel.: +48 537 500 564, e-mail: office@frenchtouchb2b.com";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(footerText, pageWidth / 2, pageSize.height - 5, { align: 'center' });
  }
};

  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const togglePreviewMode = () => {
    setIsPreviewMode(!isPreviewMode);
  };

  useEffect(() => {
    updatePackSelection();
    console.log("Pack Selection Map:", Array.from(packSelectionMapRef.current.entries()));
    console.log("Total Price:", calculateTotalPrice());
}, [selectedServices]);

  return (
    <div className='mb-12'>  
      <div className='flex flex-col md:flex-row w-full px-2 md:px-36 mt-0'>
        <div className="w-full md:w-1/3 align-top items-start">
          <div className="bg-white p-6 border border-gray-200 rounded-lg">
            <h3 className="text-lg font-bold leading-6 text-gray-900">French Touch Les 10 ans</h3>
            <p className="mt-0.5 text-sm text-gray-600 mb-3">
              Tous les services de l'édition 2024.
            </p>
            <ServicesList services={services} onServiceSelection={handleServiceSelection} />
          </div>
        </div>

        <div className="w-full mt-5 md:mt-0 md:w-2/3 align-top items-start p-6 bg-slate-50 shadow-sm border rounded-lg ml-0 md:ml-6">
          <h3 className="text-lg font-bold leading-6 text-gray-900">Votre offre</h3>
          <p className="mt-0.5 text-sm text-gray-600 mb-3">
            Personnalisez votre offre avec nos services.
          </p>
          <div className="mt-2">

             <div className='flex flex-col w-full mb-4'>

            <input
              type="text"
              name="offerNumber"
              id="offerNumber"
              value={offerNumber}
              onChange={(e) => setOfferNumber(e.target.value)}
              className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Offer Number/Name"
            />
              </div>

            <div className='flex w-full gap-4'>
              
             

              <div className='flex flex-col w-1/2'>

            {/* <label htmlFor="companyName" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
              Entreprise
            </label> */}
            <input
              type="text"
              name="companyName"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Nazwa firma"
                />
              </div>
              
              <div className='flex flex-col w-1/2'>

            {/* <label htmlFor="nipNumber" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
             Numer NIP
            </label> */}
            <input
              type="text"
              name="nipNumber"
              id="nipNumber"
              value={nipNumber}
              onChange={(e) => setNipNumber(e.target.value)}
              className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Numer NIP"
                />
                </div>
          
              </div>

            <div className='flex flex-row w-full gap-4 mt-2 mb-2'>

          <div className='flex flex-col w-1/2'>


        {/* <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
              Adresse
            </label> */}
            <input
              type="text"
              name="address"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Adres"
            />
            </div>

              <div className='flex flex-row w-1/2 gap-2'>
            <div className=' w-1/2'>
            {/* <label htmlFor="postalCode" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
              Code Postal
            </label> */}
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Kod pocztowy"
              />
              </div>

            <div className='w-1/2'>
            {/* <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
              Ville
            </label> */}
            <input
              type="text"
              name="city"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Miasto"
              />
                </div>
                </div>
              </div>

            

            
            

            <div className='flex w-full gap-6 mt-3 pb-4'>
              <div className='w-1/2'>
                <label htmlFor="dateOffer" className="block text-sm mb-1 font-medium leading-6 text-gray-900">
                  Data oferty
                </label>
                <input
                  type="date"
                  name="dateOffer"
                  id="dateOffer"
                  value={dateOffer}
                  onChange={(e) => setDateOffer(e.target.value)}
                  className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className='w-1/2'>
                <label htmlFor="validUntil" className="block text-sm mb-1 font-medium leading-6 text-gray-900">
                  Oferta ważna do
                </label>
                <input
                  type="date"
                  name="validUntil"
                  id="validUntil"
                  value={validUntil}
                  onChange={(e) => setValidUntil(e.target.value)}
                  className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className='flex w-full gap-4'>

              
              
              <div className='flex flex-col w-1/2'>

            
                </div>
          
              </div>

             <div className="md:col-span-3 mt-1">
              <label htmlFor="introText" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
                Szanowni Państwo,
              </label>
              <input
                type="text"
                name="introText"
                id="introText"
                value={introText}
                onChange={(e) => setIntroText(e.target.value)}
                className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter the introductory text"
              />
            </div>

            <div className="mt-4 -mx-6 flow-root rounded">
              <table className="min-w-full divide-y divide-gray-300 ">
                <colgroup>
                  <col className="w-8/12" />
                  <col className="w-1/6" />
                  <col className="w-1/6" />
                  <col className="w-1/6" />
                </colgroup>
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="py-3 px-4 text-left text-sm font-semibold text-gray-900">Service</th>
                    <th scope="col" className="py-2 text-left text-sm font-semibold text-gray-900">Quantité</th>
                    <th scope="col" className="py-2 text-left text-sm font-semibold text-gray-900">Remise</th>
                    <th scope="col" className="py-2 text-left text-sm font-semibold text-gray-900">Prix</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(groupedByCategory).map(([categorie, subCategories]) => (
                    <React.Fragment key={categorie}>
                      <tr>
                        <td colSpan="4" className="py-2 bg-slate-100 px-4 text-left text-sm font-semibold text-gray-900">{categorie}</td>
                      </tr>
                      {Object.entries(subCategories).map(([sousCategorie, services]) => (
                        <React.Fragment key={sousCategorie}>
                          <tr>
                            <td colSpan="4" className="py-2 px-4  border-t border-slate-400 text-left text-sm font-semibold text-slate-900">{sousCategorie}</td>
                          </tr>
                          {services.map((service, index) => (
                            <tr key={index}>
                              <td className="px-4 py-2 text-sm text-slate-600 font-normal">{service.module}</td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                {service.pack_id && packSelectionMapRef.current.get(service.pack_id)?.isFullPackSelected ? (
                                  <span>{service.quantity}</span>
                                ) : (
                                  <input
                                    type="number"
                                    value={service.quantity || 1}
                                    onChange={(e) => handleQuantityChange(service.id, e.target.value)}
                                    className="w-full p-1 text-sm rounded text-slate-700 focus:border-indigo-500 focus:ring-indigo-500"
                                  />
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                {service.pack_id && packSelectionMapRef.current.get(service.pack_id)?.isFullPackSelected ? (
                                  <span>{service.promotion}</span>
                                ) : (
                                  <input
                                    type="number"
                                    value={service.promotion || 0}
                                    onChange={(e) => handlePromotionChange(service.id, e.target.value)}
                                    className="w-full p-1 text-sm rounded text-slate-700 focus:border-indigo-500 focus:ring-indigo-500"
                                  />
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm font-semibold text-slate-700">
                                {service.pack_id && packSelectionMapRef.current.get(service.pack_id)?.isFullPackSelected ? (
                                  'Inclus dans pack'
                                ) : (
                                  `${new Intl.NumberFormat('en-US').format(((service.prix_ft || 0) * (service.quantity || 1) * (1 - (service.promotion || 0) / 100)).toFixed(2))} zł`
                                )}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot className="bg-white">
                  <tr>
                    <td colSpan="3" className="text-sm font-normal text-gray-500 px-3 py-2 text-right">Sous Total</td>
                    <td className="text-sm text-gray-500 px-3 py-2 text-right">{new Intl.NumberFormat('en-US').format(calculateTotalBeforeDiscounts())} zł</td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-sm font-normal text-gray-500 px-3 py-2 text-right">Remise</td>
                    <td className="text-sm text-gray-500 px-3 py-2 text-right">{new Intl.NumberFormat('en-US').format(calculateTotalDiscounts())} zł</td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="text-sm font-semibold text-gray-900 px-3 py-2 text-right">Total</td>
                    <td className="text-sm font-semibold text-gray-900 px-3 py-2 text-right">{new Intl.NumberFormat('en-US').format(calculateTotalPrice())} zł</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="md:col-span-3 mt-6">
              <label htmlFor="paymentTerms" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
                Warunki Platnosci
              </label>
              <input
                type="text"
                name="paymentTerms"
                id="paymentTerms"
                value={paymentTerms}
                onChange={(e) => setPaymentTerms(e.target.value)}
                className="block w-full p-3 py-2 shadow-sm rounded-md border border-slate-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Warunki Platnosci"
              />
            </div>

           

            <div className="md:col-span-3 mt-6">
              <button
                onClick={generatePDF}
                disabled={isGeneratingPDF}
                className="inline-flex w-full text-center justify-center items-center px-4 py-3 border border-transparent text-md font-semibold rounded-md shadow-sm text-white bg-slate-700 hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isGeneratingPDF && (
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0H4z"></path>
                  </svg>
                )}
                {isGeneratingPDF ? 'En cours de création' : 'Generate PDF'}
              </button>
            </div>
          </div>

          {isModalOpen && (
            <ServicesModal
              services={services}
              onClose={handleCloseModal}
              onServiceSelection={handleServiceSelection}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferConfigurator;
