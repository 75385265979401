import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import ServicesPage from './components/OfferConfigurator/ServicesPage';
import OfferConfigurator from './components/OfferConfigurator/OfferConfigurator';
import Navbar from './components/Base/Navbar';
import Home from './pages/Standard/Home';
import SignUpPage from './pages/Auth/SignUpPage';
import SignIn from './components/Auth/SignIn';
import Settings from './pages/Standard/Settings';
import OfferFT2024 from './pages/Modules/OfferFT2024';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                <><Navbar /><Home /></>
              </ProtectedRoute>
            } />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/services" element={<ProtectedRoute><><Navbar /><ServicesPage /></></ProtectedRoute>} />
            <Route path="/configurator" element={<ProtectedRoute><><Navbar /><OfferConfigurator /></></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><><Navbar /><Settings /></></ProtectedRoute>} />
            <Route path="/offer-ft2024" element={<ProtectedRoute><><Navbar /><OfferFT2024 /></></ProtectedRoute>} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
