import React, { useState, useMemo } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}



const ServicesList = ({ services, onServiceSelection }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Tous');

    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  


  const categories = useMemo(() => {
    return ['Tous', ...new Set(services.map(service => service.categorie))];
  }, [services]);

const handleToggleService = (serviceId) => {
  setSelectedIds((prevSelectedIds) => {
    const serviceIdStr = String(serviceId); // Ensure serviceId is treated as a string
    const isCurrentlySelected = prevSelectedIds.includes(serviceIdStr);
    const newSelectedIds = isCurrentlySelected
      ? prevSelectedIds.filter((id) => id !== serviceIdStr)
      : [...prevSelectedIds, serviceIdStr];

    onServiceSelection(services.filter((service) => newSelectedIds.includes(String(service.id))));
    return newSelectedIds;
  });
};


  const filteredServices = useMemo(() => {
    return activeFilter === 'Tous' ? services : services.filter(service => service.categorie === activeFilter);
  }, [activeFilter, services]);

  const paginatedServices = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredServices.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredServices, currentPage]);

const selectGoldPackageServices = () => {
  const goldPackageIds = ['136', '139', '154', '156', '158', '162']; // Example service IDs for the Gold Package as strings
  setSelectedIds(goldPackageIds);
  const selectedServices = services.filter(service => goldPackageIds.includes(String(service.id)));
  onServiceSelection(selectedServices);
};
  
  const clearSelections = () => {
    setSelectedIds([]);
    onServiceSelection([]);
  };

  const selectPackageServices = (pack) => {
  // Filter services that belong to the selected package
  const packageServices = services.filter(service => service.categorie === pack);

  // Get the IDs of the package services
  const packageServiceIds = packageServices.map(service => String(service.id));

  // Update the selected IDs state
  setSelectedIds(packageServiceIds);

  // Call the onServiceSelection callback with the selected services
  onServiceSelection(packageServices);
};
        const packages = categories.filter(category => category.startsWith('Pakiet'));


    return (
    <>
                  <button
          onClick={clearSelections}
          className="mr-1.5 px-2 py-1 mb-2 text-xs bg-white hover:bg-slate-100 transition border rounded-md text-slate-700"
        >
          Réinitialiser
          </button>
          
          <h3 className='block text-sm font-semibold leading-6 text-slate-900 mt-3 mb-1'>Les Packs</h3>

        {packages.map((pack) => (
                    <div className="inline-flex bg-slate-100 p-2 py-1 mb-1 hover:bg-slate-200 rounded-md cursor-pointer mr-2 w-fit ">

            <div className='flex flex-row'>
              
  <p
    key={pack}
    onClick={() => selectPackageServices(pack)}
    className="text-sm w-fit font-normal p-0 text-slate-500 hover:text-slate-900 "
  >
    {pack}
              </p>
              </div>
              </div>
))}
          

      <h3 className='text-sm font-semibold leading-6 text-slate-900 mt-6 -mb-1 '>Filter les services</h3>
      <div className="flex flex-wrap gap-x-4 gap-y-1 mb-3 py-2 pb-3 " style={{ scrollbarWidth: 'thin' }}>
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setActiveFilter(category)}
            className={classNames(
              'text-sm font-medium cursor-pointer ',
              activeFilter === category
                ? ' text-slate-900 font-medium  border-slate-200'
                : 'bg-white  text-slate-400 font-medium hover:text-slate-900'
            )}
          >
            {category}
          </button>
        ))}
      </div>
     <div className="mt-2">
        {paginatedServices.map((service) => (
    <div
      key={service.id}
      className={classNames(
        'relative cursor-pointer select-none py-1 pl-0 pr-9 mb-4 text-sm flex items-left rounded-md ',
        selectedIds.includes(String(service.id)) ? 'bg-sky-700/10 text-slate-900 border' : 'text-gray-900 bg-white border border-1 '
      )}
      onClick={() => handleToggleService(service.id)}
    >
      <div className='flex flex-col px-4 py-2 '>
        
        <div className="rounded-md w-fit mb-1 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{service.sous_categorie}</div>
        <div className='pl-1 text-md text-slate-900 font-medium '>{service.module}</div>
        <div className='pl-1 text-sm text-slate-500 font-normal '>{service.sous_module}</div>

      </div>
      {selectedIds.includes(String(service.id)) && (
        <span
          className="absolute inset-y-0 right-0 flex items-center pr-4"
        >
          <CheckIcon className="h-4 w-4" aria-hidden="true" />
        </span>
      )}
    </div>
        ))}
          
          {[...Array(Math.ceil(filteredServices.length / itemsPerPage)).keys()].map(number => (
  <button 
    className={`p-1 w-8 h-8 mr-2 mb-2 rounded-xl text-slate-600 ${currentPage === number + 1 ? 'bg-slate-400 text-slate-50' : 'bg-slate-100'}`}
    key={number} 
    onClick={() => setCurrentPage(number + 1)}
  >
    {number + 1}
  </button>
))}
</div>
    </>
  );
};

export default ServicesList;
