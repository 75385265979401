import React, { useState, useEffect, useMemo } from 'react';
import { FiEye, FiTrash2 } from 'react-icons/fi';
import supabase from '../../supabaseClient';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ServicesTable = ({ services, setServices, onDeleteService }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [activeFilter, setActiveFilter] = useState('Tous');
  const [formFields, setFormFields] = useState({});
  const [packs, setPacks] = useState([]);
  
  // Function to group services first by category and then by subcategory
  const groupServices = (servicesArray) => {
    return servicesArray.reduce((acc, service) => {
      if (!acc[service.categorie]) acc[service.categorie] = {};
      if (!acc[service.categorie][service.sous_categorie]) acc[service.categorie][service.sous_categorie] = [];
      acc[service.categorie][service.sous_categorie].push(service);
      return acc;
    }, {});
  };

  useEffect(() => {
    const fetchPacks = async () => {
      const { data, error } = await supabase.from('packs').select('id, pack_name');
      if (error) {
        console.error('Error fetching packs:', error);
      } else {
        setPacks(data);
      }
    };

    fetchPacks();
  }, []);

  const openEditModal = (service) => {
    setSelectedService(service);
    setFormFields({
      module: service.module,
      prix_officiel: service.prix_officiel,
      categorie: service.categorie,
      sous_categorie: service.sous_categorie,
      pack_id: service.pack_id,
      prix_ft: service.prix_ft
    });
    setIsEditModalOpen(true);
  };

  const handleFormFieldChange = (field, value) => {
    const newValue = (field === 'prix_ft' && value === '') ? null : value;
    setFormFields(prev => ({ ...prev, [field]: newValue }));
  };

  const updateService = async () => {
    const { id, ...updatedFields } = formFields;

    if (updatedFields.prix_ft === '') {
      updatedFields.prix_ft = null;
    }

    try {
      const { data, error } = await supabase
        .from('services')
        .update(updatedFields)
        .match({ id: selectedService.id });

      if (error) {
        console.error('Error updating service:', error);
        alert('Failed to update service: ' + error.message);
      } else {
        setServices(prev => prev.map(service => {
          if (service.id === selectedService.id) {
            return { ...service, ...updatedFields };
          }
          return service;
        }));
        setIsEditModalOpen(false);
      }
    } catch (error) {
      console.error('Error during service update:', error);
      alert('An error occurred during the update.');
    }
  };

  useEffect(() => {
    if (isDeleteModalOpen || isEditModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isDeleteModalOpen, isEditModalOpen]);

  const openDeleteModal = (service) => {
    setSelectedService(service);
    setIsDeleteModalOpen(true);
  };

  const deleteService = async (serviceId) => {
    console.log("Attempting to delete service with ID:", serviceId);

    try {
      const { data, error } = await supabase
        .from('services')
        .delete()
        .match({ id: serviceId });

      if (error) {
        console.error('Error deleting service:', error);
        alert(`Failed to delete service: ${error.message}`);
        return;
      }

      console.log('Deletion successful, data:', data);

      const updatedServices = services.filter(service => service.id !== serviceId);
      setServices(updatedServices);

      setIsDeleteModalOpen(false);
      alert('Service successfully deleted!');
    } catch (error) {
      console.error('Exception when calling deleteService:', error);
      alert('An unexpected error occurred when trying to delete the service.');
    }
  };

  const groupedByCategoryAndSubcategory = useMemo(() => groupServices(services), [services]);

  const categories = useMemo(() => ['Tous', ...new Set(services.map((service) => service.categorie))], [services]);

  const filteredServices = useMemo(() => {
    if (activeFilter === 'Tous') {
      return groupServices(services);
    }
    return groupServices(services.filter((service) => service.categorie === activeFilter));
  }, [activeFilter, services]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
       <div className="flex flex-wrap gap-2 mb-4 overflow-auto">
        <select
          value={activeFilter}
          onChange={(e) => setActiveFilter(e.target.value)}
          className="px-3 py-2.5 text-sm font-medium rounded-md border border-slate-200 bg-slate-50 text-slate-900 hover:bg-slate-100 "
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-0 flow-root">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="w-8/12 px-3 py-2 text-left text-sm font-semibold text-gray-900">Module</th>
                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Prix Officiel</th>
                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Object.entries(filteredServices).map(([categorie, subcategories]) => (
                    <React.Fragment key={categorie}>
                      {activeFilter === 'Tous' && (
                        <tr className="bg-slate-100">
                          <td colSpan="4" className="py-3 pl-4 pr-3 text-sm font-bold text-slate-900">{categorie}</td>
                        </tr>
                      )}
                      {Object.entries(subcategories).map(([sousCategorie, services]) => (
                        <React.Fragment key={sousCategorie}>
                          <tr className="bg-slate-50">
                            <td colSpan="4" className="py-2 pl-4 pr-3 border-t border-slate-400 text-sm font-bold text-gray-900">{sousCategorie}</td>
                          </tr>
                          {services.map((service) => (
                            <tr key={service.id}>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-slate-500 font-normal truncate">{service.module}</td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-slate-500 truncate">
                                {service.prix_ft ? `${service.prix_ft} zł` : ''}
                              </td>
                              <td className="whitespace-nowrap px-3 py-2 text-sm text-slate-500 flex justify-end items-center space-x-2">
                                <FiEye className="cursor-pointer" onClick={() => openEditModal(service)} />
                                <FiTrash2 className="cursor-pointer" onClick={() => openDeleteModal(service)} />
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {isEditModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-5 w-1/3 rounded-lg">
            <h2 className="text-lg font-semibold">Modifier un service</h2>
            
            <div>
              <label className="block mt-2 text-sm font-medium leading-6 text-gray-900">Categorie</label>
              <input
                type="text"
                value={formFields.categorie || ''}
                onChange={e => handleFormFieldChange('categorie', e.target.value)}
                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label className="block mt-2 text-sm font-medium leading-6 text-gray-900">Sous Categorie</label>
              <input
                type="text"
                value={formFields.sous_categorie || ''}
                onChange={e => handleFormFieldChange('sous_categorie', e.target.value)}
                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label className="block mt-2 text-sm font-medium leading-6 text-gray-900">Module</label>
              <input
                type="text"
                value={formFields.module || ''}
                onChange={e => handleFormFieldChange('module', e.target.value)}
                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label className="block mt-2 text-sm font-medium leading-6 text-gray-900">Prix Officiel</label>
              <input
                type="text"
                value={formFields.prix_ft || ''}
                onChange={e => handleFormFieldChange('prix_ft', e.target.value)}
                className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className='flex flex-row space-x-3 mt-4'>
              <button onClick={updateService} className="w-1/2 mt-4 px-4 py-2 bg-slate-700 text-sm  font-medium hover:bg-slate-800 text-white rounded">
                Valider
              </button>
              <button  
                onClick={() => setIsEditModalOpen(false)}
                className="w-1/2 mt-4 px-4 py-2 bg-slate-200 text-sm  font-medium hover:bg-slate-300 text-slate-500 rounded">
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg">
            <h2 className="text-lg font-semibold">Supprimer le service</h2>
            <p className='text-sm text-slate-500'>Voulez-vous supprimer ce service ?</p>
            <div className='flex flex-row space-x-3 mt-4'>
              <button 
                onClick={() => deleteService(selectedService.id)}
                className="w-1/2 mt-4 px-4 py-2 bg-red-600 text-sm  font-medium hover:bg-red-700 text-white rounded">
                Supprimer
              </button>
              <button 
                onClick={() => setIsDeleteModalOpen(false)}
                className="w-1/2 mt-4 px-4 py-2 bg-slate-200 text-sm  font-medium hover:bg-slate-300 text-slate-500 rounded">
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesTable;
