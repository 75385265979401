import React, { useState, useEffect } from 'react';
import supabase from '../../supabaseClient';

const ServiceForm = ({ onServiceAdded, isOpen, onClose }) => {
  const [categorie, setCategorie] = useState('');
  const [sousCategorie, setSousCategorie] = useState('');
  const [module, setModule] = useState('');
  const [prixOfficiel, setPrixOfficiel] = useState('');
  const [prixFrenchTouch, setPrixFrenchTouch] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase.from('services').select('categorie');
      if (error) {
        console.error('Error fetching categories:', error);
      } else {
        const uniqueCategories = Array.from(new Set(data.map(d => d.categorie)));
        setCategories(uniqueCategories);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data, error } = await supabase.from('services').insert([
      { 
        categorie, 
        sous_categorie: sousCategorie, 
        module, 
        prix_ft: parseFloat(prixFrenchTouch)
      },
    ]);

    if (error) {
      console.error('Error inserting new service:', error);
      return;
    }

    // Reset form fields
    setCategorie('');
    setSousCategorie('');
    setModule('');
    setPrixOfficiel('');
    setPrixFrenchTouch('');

    onServiceAdded();
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-5 mx-auto py-3 border w-2/5 shadow-lg rounded-md bg-white">
        <button onClick={onClose} className="absolute top-0 right-0 m-2">X</button>
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-4 space-y-4 bg-white rounded-lg">
          <h4 className='font-bold'>Ajouter un service</h4>

          {/* Catégorie Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Catégorie</label>
            <select 
              value={categorie} 
              onChange={(e) => setCategorie(e.target.value)} 
              required 
              className="mt-1 p-2 block w-full border border-gray-300 text-sm rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Sélectionner une catégorie</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>{cat}</option>
              ))}
            </select>
          </div>

          {/* Sous-catégories Free Text */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Sous-catégories</label>
            <input 
              type="text" 
              value={sousCategorie} 
              onChange={(e) => setSousCategorie(e.target.value)} 
              required 
              className="mt-1 p-2 block w-full border border-gray-300 text-sm rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Module Free Text */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Module</label>
            <input 
              type="text" 
              value={module} 
              onChange={(e) => setModule(e.target.value)} 
              placeholder="Module" 
              required 
              className="mt-1 p-2 block w-full text-sm border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Prix Officiel */}
         

          {/* Prix French Touch */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Prix Officiel</label>
            <input 
              type="number" 
              value={prixFrenchTouch} 
              onChange={(e) => setPrixFrenchTouch(e.target.value)} 
              placeholder="Prix French Touch" 
              required 
              className="mt-1 p-2 block w-full border text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Submit Button */}
          <button type="submit" className="w-full px-4 py-2 bg-slate-700 text-white font-bold rounded-md hover:bg-slate-800 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50">
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
};

export default ServiceForm;
