import React, { useState } from 'react';
import supabase from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

const handleSignIn = async (e) => {
  e.preventDefault();
  setLoading(true);
  const { error, session } = await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });

  if (error) {
    alert(error.message);
    setLoading(false);
  } else {
    // Ensure session is set before redirect
    if (session) {
      alert('Signed in successfully!');
      navigate('/configurator');
    } else {
      alert('Session not established, please try again.');
    }
    setLoading(false);
  }
};


  // Handle Google sign in
  const handleGoogleSignIn = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });

    if (error) {
      alert('Error signing in with Google: ' + error.message);
    } else {
      alert('Signed in with Google successfully!');
      // Redirect to your desired page after sign in
    }
  };

  return (
    <div className='flex flex-col bg-slate-100 items-center justify-center h-screen'>
      <div className="rounded-lg border  bg-white shadow-sm text-card-foreground mx-auto w-full mx-4 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4" data-v0-t="card">
        <form onSubmit={handleSignIn}>
          <h1 className='text-xl font-bold text-left mt-6 ml-6 tracking-tight'>Anor sp. z o.o.</h1>
          {/* <img src="images/bsr-logo.png" alt="BackstageRate Logo" className="w-40 mr-auto ml-5 mt-6 -mb-2" /> */}
          <div className="flex flex-col space-y-1.5 p-6 pt-4">
            <h3 className="whitespace-nowrap font-semibold tracking-tight text-xl">Sign in</h3>
            <p className="text-sm text-muted-foreground">
              Access your private Anor account.
            </p>
          </div>
          <div className="p-6 pt-2">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <label htmlFor="email" className="text-sm font-medium">Email</label>
                <input type="email" id="email" placeholder="email@address.com" className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm" required value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <label htmlFor="password" className="text-sm font-medium">Password</label>
                  {/* <a href="/reset-password" className="ml-auto text-sm underline">Forgot your password?</a> */}
                </div>
                <input type="password" id="password" placeholder="••••••••••" className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm" required value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <button type="submit" className="inline-flex items-center text-white justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full bg-slate-800 hover:bg-slate-900">
              
                Sign in
              </button>
              {/* <button type="button" onClick={handleGoogleSignIn} className=" w-full inline-flex items-center justify-center rounded-md border border-input bg-background text-sm font-medium hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2">
                <img src="/images/google-icon.png" alt="Google Icon" className='w-4 mr-2' />Continuer avec Google
              </button> */}
            </div>
            {/* <div className="mt-6 text-center text-sm">
              Vous n'avez pas de compte ?
              <a href="/signup" className="underline ml-2">
                Créer mon compte
              </a>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
