import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { session, loading } = useAuth();

  if (loading) {
    return <div></div>;
  }

  return session ? children : <Navigate to="/signin" replace />;
};

export default ProtectedRoute;
