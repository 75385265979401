import React, { useState } from 'react';
import OfferConfigurator from '../../components/OfferConfigurator/OfferConfigurator';
import ServicesPage from '../../components/OfferConfigurator/ServicesPage'; // Ensure path is correct

const OfferFT2024 = () => {
  const [activeTab, setActiveTab] = useState('configurator');

  return (
      <div className="mt-6">
          <h1 className="text-2xl mx-4 md:mx-36 font-bold text-slate-900">Offre French Touch 2024</h1>
          <p className='text-slate-600 mx-4 md:mx-36 mb-4'>Générer une offre de vente.</p>
      <div className="mx-4 md:mx-36 mb-4 ">
        <ul className="flex cursor-pointer" aria-label="Tabs">
          {/* Tab items */}
          <li className={`mr-1 ${activeTab === 'configurator' ? '' : ''}`} 
              onClick={() => setActiveTab('configurator')}>
            <a className={`inline-block  py-2 px-3 rounded-md text-sm font-medium ${activeTab === 'configurator' ? 'text-indigo-700 bg-indigo-300/20' : 'text-slate-400 bg-slate-50 hover:text-indigo-700 hover:bg-indigo-300/20'}`}>
              Offre
            </a>
          </li>
          <li className={`${activeTab === 'services' ? '' : ''}`} 
              onClick={() => setActiveTab('services')}>
            <a className={`inline-block  py-2 px-3 rounded-md text-sm font-medium ${activeTab === 'services' ? 'text-indigo-800 bg-indigo-300/20' : 'text-slate-400 bg-slate-100 hover:text-gray-800'}`}>
              Services
            </a>
          </li>
        </ul>
      </div>

      <div className="p-0">
        {activeTab === 'configurator' && <OfferConfigurator />}
        {activeTab === 'services' && <ServicesPage />}
      </div>
    </div>
  );
};

export default OfferFT2024;
