// Homepage.js
import React from 'react';
import { DocumentPlusIcon } from '@heroicons/react/24/solid'


const Settings = () => {
  return (
    <div className='flex flex-row justify-left px-28'>

     </div>
  );
};

export default Settings;
