import React from 'react';
import { Disclosure } from '@headlessui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { Bars2Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../context/AuthContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = () => {
  const location = useLocation();
    const { signOut } = useAuth();


  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-3 focus:ring-inset focus:ring-slate-500 sm:hidden">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars2Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
              <div className="flex-1 flex items-center justify-left pl-2 sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <NavLink to="/" className='text-xl font-bold text-slate-900'>
                  ANOR
                    </NavLink>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        classNames(
                          'inline-flex items-center px-1 text-sm font-medium h-full navcustom',
                          location.pathname === '/'
                            ? 'text-gray-900 border-b-2 border-slate-700'
                            : 'text-gray-500 hover:text-gray-700'
                        )
                      }
                    >
                      Modules
                    </NavLink>
                    
                    <NavLink
                      to="/settings"
                      className={({ isActive }) =>
                        classNames(
                          'inline-flex items-center px-1 text-sm font-medium h-full navcustom',
                          location.pathname === '/settings'
                            ? 'text-gray-900 border-b-2 border-slate-700'
                            : 'text-gray-500 hover:text-gray-700'
                        )
                      }
                    >
                      Réglages
                    </NavLink>
                    {/* Add more navigation links if needed */}
                  </div>
                </div>
              </div>
                    {/* <button className='text-sm text-slate-500 hover:text-slate-800' onClick={signOut}>Se déconnecter</button>   */}

              {/* Add right-side icons or profile dropdown if needed */}
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-3">
              <Disclosure.Button
                as={NavLink}
                to="/"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Accueil
              </Disclosure.Button>
              <Disclosure.Button
                as={NavLink}
                to="/settings"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Réglages
              </Disclosure.Button>
             
              {/* Add more navigation links for mobile menu if needed */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
