import React, { useEffect, useState } from 'react';
import ServiceForm from '../MyServices/ServiceForm';
import ServicesTable from '../MyServices/ServicesTable';
import supabase from '../../supabaseClient';

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const fetchServices = async () => {
    const { data, error } = await supabase.from('services').select('*');
    if (error) {
      console.error('Error fetching services:', error);
      return;
    }
    setServices(data);
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen); // Function to toggle modal visibility

  const handleServiceAdded = () => {
    fetchServices();
    toggleModal(); // Close the modal after adding a service
  };

  const deleteService = async (serviceId) => {
    const { error } = await supabase
      .from('services')
      .delete()
      .match({ id: serviceId });

    if (error) {
      console.error('Error deleting service:', error);
    } else {
      // Refresh the services list after deletion
      fetchServices();
    }
  };

 return (
    <div>
    <div className='flex w-full px-0 md:px-28'>
      <div className="w-1/3 pl-8 flex align-top items-start">
        <div className="w-full bg-white p-6 border border-gray-200 rounded-lg">
          <h3 className="text-lg font-semibold leading-6 text-slate-900">Ajouter des services</h3>
          <p className="mt-1 text-sm text-gray-600 mb-4">
            Ajouter un service à la base de données de services French Touch.
          </p>
          <button
            onClick={toggleModal}
            className="bg-slate-700 text-white px-4 py-2 rounded text-sm font-semibold hover:bg-slate-800"
          >
            Ajouter un service
          </button>
        </div>
      </div>
      <div className="w-full">
<ServicesTable
      services={services}
      setServices={setServices}
      onDeleteService={(id) => setServices(currentServices => currentServices.filter(service => service.id !== id))}
    />      </div>
      <ServiceForm onServiceAdded={handleServiceAdded} isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  </div>
  );
};

export default ServicesPage;
