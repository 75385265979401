// Homepage.js
import React from 'react';
import { DocumentPlusIcon } from '@heroicons/react/24/solid'


const Home = () => {
  return (
    <div className='flex flex-row justify-left px-0 md:px-28'>
    <div className=" w-full md:w-1/3 left-0 px-6 md:px-10 pt-6 md:pt-8">
      <a href="/offer-ft2024" >
      <div className="w-full border rounded-lg p-6 border-slate-200 shadow-sm hover:shadow-md transition flex flex-col ">
              <img
      className="block w-12 h-auto mb-4"
      src="/images/ft-logo.png" // Provide the correct path to the image
      alt="Logo"
                  />
      <h1 className="text-md font-bold mb-1">Offre French Touch 2024</h1>
      <p className="text-sm text-gray-600">Générer une offre de vente.</p>
        </div>
        </a>
      </div>
     </div>
  );
};

export default Home;
