import React from 'react';
import SignUp from '../../components/Auth/SignUp';

const SignUpPage = () => {
  return (
    <div class='bg-slate-100'>
      <SignUp />
      <p>
      </p>
    </div>
  );
};

export default SignUpPage;
