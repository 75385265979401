import React, { useState } from 'react';
import supabase from '../../supabaseClient';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      alert("Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      const { error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (signUpError) {
        throw signUpError;
      }

      // Immediately try to sign in after successful sign up
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        throw signInError;
      }

      // Redirect to additional info page
      window.location.href = '/onboarding';
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Google sign in function
const handleGoogleSignIn = async () => {
    const { user, session, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
            redirectTo: `${window.location.origin}/onboarding` // Redirect to /onboarding after sign-in
        }
    });

    if (error) {
        alert('Error signing in: ' + error.message);
    } else if (user) {
        // Optionally handle post-sign-in logic here if needed
    }
};

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <div className="rounded-lg border bg-white shadow-sm text-card-foreground mx-auto w-full mx-4 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <form onSubmit={handleSignUp}>
          <img src="images/bsr-logo.png" alt="BackstageRate Logo" className="w-40 mr-auto ml-5 mt-6 -mb-2" />
          <div className="flex flex-col space-y-1.5 p-6 pb-0">
            <h3 className="whitespace-nowrap font-semibold tracking-tight text-2xl">Créer mon compte</h3>
            <p className="text-sm text-muted-foreground">
              Créez votre compte BackstageRate.
            </p>
          </div>
          
          <div className="p-6">
            <button type="button" onClick={handleGoogleSignIn} className="mb-4 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full">
              <img src="/images/google-icon.png" alt="Google icon" className='w-4 mr-2'></img>Continuer avec Google
            </button>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <label htmlFor="email" className="text-sm font-medium">Email</label>
                <input type="email" id="email" placeholder="adresse@mail.com" className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" required value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="grid gap-2">
                <label htmlFor="password" className="text-sm font-medium">Mot de passe</label>
                <input type="password" id="password" placeholder="••••••••••" className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" required value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="grid gap-2">
                <label htmlFor="confirmPassword" className="text-sm font-medium">Répétez le mot de passe</label>
                <input type="password" id="confirmPassword" placeholder="••••••••••" className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
            </div>
            <button type="submit" className="mt-4 inline-flex items-center text-white justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full bg-indigo-700 hover:bg-indigo-600">
              {loading ? 'Loading...' : 'Créer mon compte'}
            </button>
          </div>
          <div className="text-center text-sm mb-6">
            Vous avez déjà un compte ?
            <a className="underline ml-2" href="/signin">
              Connectez-vous
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
